import React, { useContext, useEffect, useState } from "react";
import Slide from "react-reveal/Slide";
import PageNumberContext from "../../context/PageNumber";
import ServiceMultiselect from "./ServiceMultiselect";
import cogoToast from 'cogo-toast'

export const updateLocalStorage = (value, field) => {
  let formData = JSON.parse(localStorage.getItem("loginPage2"));
  if (field == "service") {
    formData.service = value;
  } else if (field == "mot") {
    formData.mot = value;
  } else if (field == "tutorGender") {
    formData.tutorGender = value;
  } else if (field == "nodays") {
    formData.nodays = value;
  } else if (field == "tuitionDate") {
    formData.tuitionDate = value;
  }

  localStorage.setItem("loginPage2", JSON.stringify(formData));
};

const Page2 = () => {
  const { nextPage, prevPage } = useContext(PageNumberContext);
  const [service, setService] = useState("");
  const [tutorGender, setTutorGender] = useState("");
  const [nodays, setNodays] = useState("");
  const [tuitionDate, setTuitionDate] = useState("");

  // options to display for multiple select

  useEffect(() => {
    if (localStorage.getItem("loginPage2")) {
      const formData = JSON.parse(localStorage.getItem("loginPage2"));
      // console.log(formData);
      setService(formData.service);
      setTutorGender(formData.tutorGender);
      setTuitionDate(formData.tuitionDate);
      setNodays(formData.nodays);
    } else {
      const formData = {
        service: "Select",
        tutorGender: "",
        nodays: "",
        tuitionDate: "",
      };
      localStorage.setItem("loginPage2", JSON.stringify(formData));
    }
  }, []);

  

  const onInputChange = (e) => {
    // console.log(e.target.id);
    if (e.target.id === "service") {
      // console.log(e.target.value);
      setService(e.target.value);
      updateLocalStorage(e.target.value, e.target.id);
    } else if (e.target.id === "tutorGender") {
      // console.log(e.target.value);
      setTutorGender(e.target.value);
      updateLocalStorage(e.target.value, e.target.id);
    } else if (e.target.id === "nodays") {
      // console.log(e.target.value);
      setNodays(e.target.value);
      updateLocalStorage(e.target.value, e.target.id);
    } else if (e.target.id === "tuitionDate") {
      // console.log(e.target.value);
      setTuitionDate(e.target.value);
      updateLocalStorage(e.target.value, e.target.id);
    }
  };

  const next = () => {


    if(tutorGender == "" || nodays == "" || tuitionDate == ""){
      cogoToast.warn("Please enter all the fields!", {position:'top-right'})
    }else{
      nextPage();
    }
  };

  const prev = () => {
    prevPage();
  };

  return (
    <>
      <Slide right>

        {/* opening modal for selecting multiple options  */}
        <div className="mt-3 mb-3 text-left space-y-1">
          <label className=" text-md text-left">
            Select a service you are looking for
          </label>
          <ServiceMultiselect />
        </div>
         
        <div className="mt-3 mb-3 text-left space-y-1">
          <label className=" text-md text-left">
            Require male or female tutor
          </label>
          <div>
            <select
              id="tutorGender"
              value={tutorGender}
              onChange={(e) => onInputChange(e)}
              className="px-3 py-2 text-gray-500 border border-gray-300 outline-none rounded-lg w-full
              focus:border-blue-500 transform duration-300 ease-in-out"
            >
              <option>select</option>
              <option>Male</option>
              <option>Female</option>
            </select>
          </div>
        </div>
        <div className="mt-3 mb-3 text-left space-y-1">
          <label className=" text-md text-left">
            How many days a week do you need tuition
          </label>
          <input
            type="text"
            id="nodays"
            value={nodays}
            onChange={(e) => onInputChange(e)}
            // placeholder="..."
            className=" px-3 py-2 border border-gray-300 outline-none rounded-lg w-full
            focus:border-blue-500 transform duration-300 ease-in-out"
          />
        </div>

        <div className="mt-3 mb-3 text-left space-y-1">
          <label className=" text-md text-left">
            When would you like to start tuition
          </label>
          <div>
            <select
              id="tuitionDate"
              value={tuitionDate}
              onChange={(e) => onInputChange(e)}
              className="px-3 py-2 text-gray-500 border border-gray-300 outline-none rounded-lg w-full
              focus:border-blue-500 transform duration-300 ease-in-out"
            >
              <option>select</option>
              <option value="immediately">Immediately</option>
              <option value="week">Within One Week</option>
              <option value="month">Within One Month</option>
              <option value="after_one">After One Month</option>
            </select>
          </div>
        </div>

        <div className="mt-3 mb-3 text-left block md:flex space-x-3 space-y-3 md:space-x-3 justify-center md:justify-end">
          <button
            onClick={() => prev()}
            className=" px-4 py-2 rounded-lg outline-none border border-cyan-500 hover:bg-cyan-600 
            hover:text-white text-cyan-500 transform duration-300"
          >
            Prev
          </button>
          <button
            onClick={() => next()}
            className=" px-4 py-2 rounded-lg outline-none bg-cyan-500 hover:bg-cyan-600 text-white 
            transform duration-300"
          >
            Next
          </button>
        </div>
      </Slide>
    </>
  );
};

export default Page2;
