import React, { useContext, useEffect } from "react";
import image2 from "../image2.jpg";
import Slide from "react-reveal/Slide";
import PageNumberContext from "../../context/PageNumber";
import UsersIcon from "@heroicons/react/20/solid/UsersIcon";
import Page1 from "./Page1.js";
import Page2 from "./Page2.js";
import Page3 from "./Page3.js";
import { TutorQuote } from "../../components/Quote";


const Register = () => {
  const { pageNumber, setPageNumber } = useContext(PageNumberContext);

  useEffect(() => {
    // checking the localStorage for previuos session data
    if (!localStorage.getItem("tutorPage1")) {
      // creating a cookie for storing the new session data for page1
      // console.log("present");
      const formData = {
        name: "",
        email: "",
        gender: "",
        number: "",
        highQual: "",
      };
      localStorage.setItem("tutorPage1", JSON.stringify(formData));
    } else {
      // console.log(localStorage.getItem("tutorPage1"));
    }

    // checking for form time
    if (!localStorage.getItem("formTime1")) {
      // creating a form time if it's not there
      const createdAt = new Date().getTime().toString();
      localStorage.setItem("formTime1", JSON.stringify(createdAt));
    } else {
      if (localStorage.getItem("tutorPage3")) {
        setPageNumber(3);
      } else if (localStorage.getItem("tutorPage2")) {
        setPageNumber(2);
      } else {
        setPageNumber(1);
      }
    }
  }, []);

  return (
    <div className="w-full h-full">
      <div className=" grid h-full w-full mb-4 space-x-3 grid-cols-1 md:grid-cols-2">
        <div className="h-full p-10">
          <Slide left>
            <div className=" flex justify-center">
              <div className="image w-2/3 h-1/2 flex justify-center py-4">
                <img
                  className="drop-shadow-2xl rounded-s-full rounded-e-full rounded-t-full"
                  alt="login image"
                  src={image2}
                ></img>
              </div>
            </div>
            <div
              className="show-content drop-shadow-2xl space-y-4 text-left p-10
             bg-cyan-500 text-white rounded-lg font-semibold"
            >
              <div className="question text-lg">
                1) Are you passionate about making a positive impact on the
                lives of special needs children?
              </div>
              <div className="question text-lg">
                2) Do you have experience working with children with learning
                differences, behavioral challenges, or other special needs?
              </div>
              <div className="question text-lg">
                If so, we invite you to join our team of shadow teachers and
                help children reach their full potential!
              </div>
              <div className="question text-lg">
                We are looking for individuals who are enthusiastic,
                compassionate, and patient, and who have experience in working
                with special needs children.
              </div>
            </div>
          </Slide>
        </div>
        <div>
          <div className="p-10">
            <div className=" text-left text-2xl font-semibold flex space-x-6 items-center text-gray-600 justify-start">
              <UsersIcon className=" w-6 h-6" />
              <label>Become a tutor</label>
            </div>
            {pageNumber == 1 ? (
              <Page1 />
            ) : pageNumber == 2 ? (
              <Page2 />
            ) : pageNumber == 3 ? (
              <Page3 />
            ) : (
              ""
            )}
          </div>
          <Slide right>
          <div className="w-full px-10 mb-4 md:mb-0">
            <a
              href="https://homeshiksha.com/hs-tutors/"
              type="button"
              className="text-white bg-blue-300 rounded-lg py-3 text-md
              w-full hover:bg-blue-400 hover:text-blue-600
              transform duration-300 font-medium text-center"
            >
              Already a Tutor? Sign in Here
            </a>
          </div>
          </Slide>
        </div>
      </div>

      {/* displaying quote section  */}
      <TutorQuote />
    </div>
  );
};

export default Register;
