import React, { useState, useContext, useEffect } from "react";
import Slide from "react-reveal/Slide";
import PageNumberContext from "../../context/PageNumber";
import cogoToast from "cogo-toast";
import axios from "axios";

const Page3 = () => {
  const { prevPage } = useContext(PageNumberContext);
  // const [toa, setToa] = useState("");
  const [address, setAddress] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("tutorPage3")) {
      const formData = JSON.parse(localStorage.getItem("tutorPage3"));
      //   console.log(formData);
      // setToa(formData.toa);
      setAddress(formData.address);
    } else {
      const formData = {
        // toa: "",
        address: "",
      };
      localStorage.setItem("tutorPage3", JSON.stringify(formData));
    }
  }, []);

  const updateLocalStorage = (value, field) => {
    let formData = JSON.parse(localStorage.getItem("tutorPage3"));
    // if (field == "toa") {
    //   formData.toa = value;
    // } else 
    if (field == "address") {
      formData.address = value;
    }

    localStorage.setItem("tutorPage3", JSON.stringify(formData));
  };

  const onInputChange = (e) => {
    // console.log(e.target.id);
    // if (e.target.id === "toa") {
    //   console.log(e.target.value);
    //   setToa(e.target.value);
    //   updateLocalStorage(e.target.value, e.target.id);
    // } else

    if (e.target.id === "address") {
      // console.log(e.target.value);
      setAddress(e.target.value);
      updateLocalStorage(e.target.value, e.target.id);
    }
  };

  // when terms and conditions is clicked
  const tandc = () => {
    window.open("https://shadow.homeshiksha.com/?page_id=406");
  };

  const prev = () => {
    prevPage();
  };

  // to handle submit button
  const handleSubmit = () =>{
    if(address == ""){
      cogoToast.warn("Please Enter Address!", {
        position: "top-right",
      });
    }
    else if(isChecked == false){
      cogoToast.warn("Please Agree with Terms and Conditions", {
        position: "top-right",
      });
    } else{
      const formData = JSON.parse(localStorage.getItem("tutorPage1"));
      const formData2 = JSON.parse(localStorage.getItem("tutorPage2"));
      // console.log(formData.name.length);
      let fullname = formData.name;
      let index;

      for (let i = fullname.length; i > 0; i--) {
        if (fullname[i] == " ") {
          index = i;
          break;
        }
      }

      const firstname = fullname.substring(0, index);
      const lastname = fullname.substring(index, fullname.length);
      // console.log(firstname, lastname);

      // calling api for submitting the request

      const data ={
        url: "v1/tutor",
        method: "POST",
        data: {
          tutorData : {
            firstName: firstname,
            lastName: lastname,
            gender: formData.gender,
            phone: formData.number,
            do_you_have_vehicle: formData2.vehicle,
            email: formData.email,
            qualification: formData.highQual,
            area_were_you_can_teach: formData2.areas,
            subject_you_can_teach: formData2.subjects,
            address: address,
            comment: "bhalo ",
            extra_activities: "wating ",
            city: formData2.currentCity,
            teching_mode: " ",
            shadow_service_needed: formData2.service
          }
        }
      }

      // console.log('data sent', data)

      axios(data)
        .then((res) => {
          // console.log(res.data);
          cogoToast.success("Your have been registered successfully!",{position:'top-center', heading: 'Success !'})
          localStorage.clear()
          setTimeout(()=>{
            localStorage.clear()
            // window.open("https://homeshiksha.com/hs-tutors/")
            window.open('https://homeshiksha.com/hs-tutors', '_blank', 'noreferrer')
            localStorage.clear()
            
            // window.location.reload(true)
          },5000)
        })
        .catch((err) => {
          if(err.response.data.message){
            cogoToast.error(`${err.response.data.message}`)
          }
          else{
            cogoToast.error(`${err.response.data.errors[0].msg}`)
          }
          
          // console.log(err);
        });

    }
  }

  return (
    <>
      <Slide right>
        {/* <div className="mt-3 mb-3 text-left space-y-1">
          <label className=" text-md text-left">Current city</label>
          <input
            placeholder="toa"
            value={toa}
            onChange={(e) => onInputChange(e)}
            className=" px-3 py-2 border border-gray-300 outline-none rounded-lg w-full"
          />
        </div> */}
        <div className="mt-3 mb-3 text-left space-y-1">
          <label className=" text-md text-left">Address</label>
          <textarea
            id="address"
            value={address}
            onChange={(e) => onInputChange(e)}
            placeholder="type here..."
            className=" px-3 py-2 border border-gray-300 outline-none rounded-lg w-full"
          />
        </div>
        <div className="mt-3 mb-3 text-left space-y-1">
          <div className="flex space-x-3 px-2 items-center">
            <input
              checked={isChecked}
              onClick={() => setIsChecked(!isChecked)}
              className="h-4 w-4"
              type="checkbox"
            />
            <p>
              I have read and accept{" "}
              <span
                onClick={() => tandc()}
                className=" text-blue-500 cursor-pointer"
              >
                {" "}
                terms and conditions{" "}
              </span>
            </p>
          </div>
        </div>

        <div className="mt-3 mb-3 text-left space-x-3 space-y-1 flex md:justify-end">
          <button
            onClick={() => prev()}
            className=" px-4 py-2 outline-none rounded-lg border border-[#06B6C3] text-[#06B6C3]"
          >
            Prev
          </button>
          <button onClick={()=> handleSubmit()} className=" px-4 py-2 rounded-lg outline-none focus:ring focus:ring-[#06B6C3] focus:ring-offset-2 bg-[#06B6C3] hover:bg-[#107178] text-white transform duration-300">
            Submit
          </button>
        </div>
      </Slide>
    </>
  );
};

export default Page3;
