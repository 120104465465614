import React, { useContext, useState, useEffect } from "react";
import Slide from "react-reveal/Slide";
import PageNumberContext from "../../context/PageNumber";
import CategoryMultiselect from "./CategoryMultiselect";
import cogoToast from "cogo-toast";
import axios from "axios";
import "primereact/resources/primereact.min.css";
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

export const updateLocalStorage = (value, field) => {
  let formData = JSON.parse(localStorage.getItem("tutorPage2"));
  if (field == "service") {
    formData.service = value;
  } else if (field == "currentCity") {
    formData.currentCity = value;
  } else if (field == "vehicle") {
    formData.vehicle = value;
  } else if (field == "areas") {
    formData.areas = value;
  } else if (field == "subjects") {
    formData.subjects = value;
  }

  localStorage.setItem("tutorPage2", JSON.stringify(formData));
};

const Page2 = () => {
  const { nextPage, prevPage } = useContext(PageNumberContext);
  const [service, setService] = useState("");
  const [currentCity, setCurrentCity] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [areas, setAreas] = useState("");
  const [subjects, setSubjects] = useState("");

  // for showing areas
  const [showList, setShowList] = useState([]);
  const [selectedCities, setSelectedCities] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("tutorPage2")) {
      const formData = JSON.parse(localStorage.getItem("tutorPage2"));
      // console.log(formData);
      setService(formData.service);
      setVehicle(formData.vehicle);
      setSubjects(formData.subjects);
    } else {
      const formData = {
        service: "Select",
        vehicle: "",
        subjects: "",
      };
      localStorage.setItem("tutorPage2", JSON.stringify(formData));
    }
  }, []);

  const fetchLocallity = (id) => {
    axios
      .get(`v1/locality?cityId=${id}`)
      .then((res) => {
        // console.log(res.data);
        const data = res.data;
        let newArray = [];
        data.forEach((element) => {
          const jsonData = {
            name: element.area_name,
            code: element.area_id,
          };
          newArray.push(jsonData);
        });
        setShowList(newArray);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const onInputChange = (e) => {
    // console.log(e.target.id);
    if (e.target.id === "currentCity") {
      // console.log(e.target.value);
      setCurrentCity(e.target.value);
      fetchLocallity(e.target.value);
      updateLocalStorage(e.target.value, e.target.id);
    } else if (e.target.id === "vehicle") {
      // console.log(e.target.value);
      setVehicle(e.target.value);
      updateLocalStorage(e.target.value, e.target.id);
    } else if (e.target.id === "areas") {
      // console.log(e.target.value);
      setAreas(e.target.value);
      updateLocalStorage(e.target.value, e.target.id);
    } else if (e.target.id === "subjects") {
      // console.log(e.target.value);
      setSubjects(e.target.value);
      updateLocalStorage(e.target.value, e.target.id);
    }
  };

  const next = () => {
    if (
      currentCity == "" ||
      vehicle == "" ||
      selectedCities == null ||
      subjects == ""
    ) {
      cogoToast.warn("Please enter all the fields!", { position: "top-right" });
    } else {
      nextPage();
    }
  };

  const prev = () => {
    prevPage();
  };

  const updatesAreas = (value) => {
    let newString = "";
    value.forEach((element) => {
      newString += `${element.name}, `;
    });
    setSelectedCities(value);
    updateLocalStorage(newString, "areas");
    // console.log(value)
    // console.log(newString)
  };

  return (
    <>
      <Slide right>
        {/* opening modal for selecting multiple options  */}
        <div className="mt-3 mb-3 text-left space-y-1">
          <label className=" text-md text-left">
            Select a category in which you are into
          </label>
          <CategoryMultiselect />
        </div>

        {/* for selecting city  */}

        <div className="mt-3 mb-3 text-left space-y-1">
          <label className=" text-md text-left">Current city</label>
          <div>
            <select
              id="currentCity"
              value={currentCity}
              onChange={(e) => onInputChange(e)}
              className="px-3 py-2 text-gray-500 border border-gray-300 outline-none rounded-lg w-full
              focus:border-blue-500 transform duration-300 ease-in-out"
            >
              <option>select</option>
              <option value="1">Hyderabad</option>
              <option value="2">Bangalore</option>
              <option value="3">Pune</option>
              <option value="4">Dehradun</option>
              <option value="6">Online-Dun</option>
              <option value="7">Raipur/Bhilai/Durg</option>
              <option value="8">Chennai</option>
              <option value="9">Vijayawada</option>
              <option value="10">Mumbai</option>
              <option value="11">Gurgaon</option>
              <option value="12">Delhi</option>
              <option value="16">Mysore</option>
              <option value="5">Other</option>
            </select>
          </div>
        </div>

        {/* ares where you can teach  */}

        <div className="mt-3 mb-3 text-left space-y-1">
          <label className=" text-md text-left">Locality</label>
          <MultiSelect
            className=" w-full"
            filter
            value={selectedCities}
            onChange={(e) => updatesAreas(e.value)}
            options={showList}
            display="chip"
            optionLabel="name"
            placeholder="Select Areas"
          />
        </div>

        <div className="mt-3 mb-3 text-left space-y-1">
          <label className=" text-md text-left">
            Do you have a vehicle to commute?
          </label>
          <select
            id="vehicle"
            value={vehicle}
            onChange={(e) => onInputChange(e)}
            className="px-3 py-2 text-gray-500 border border-gray-300 outline-none rounded-lg w-full"
          >
            <option>select</option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>

        <div className="mt-3 mb-3 text-left space-y-1">
          <label className=" text-md text-left">Subjects you can teach?</label>
          <input
            placeholder="type here..."
            id="subjects"
            value={subjects}
            onChange={(e) => onInputChange(e)}
            className=" px-3 py-2 border border-gray-300 outline-none rounded-lg w-full"
          />
        </div>
        <div className="mt-3 mb-3 text-left block md:flex space-x-3 space-y-3 md:space-x-3 justify-center md:justify-end">
          <button
            onClick={() => prev()}
            className=" px-4 py-2 rounded-lg outline-none border border-[#107178] hover:bg-[#107178] 
            hover:text-white text-[#107178] transform duration-300"
          >
            Prev
          </button>
          <button
            onClick={() => next()}
            className=" px-4 py-2 rounded-lg outline-none focus:ring focus:ring-[#06B6C3] 
            focus:ring-offset-2 bg-[#06B6C3] hover:bg-[#107178] text-white transform duration-300"
          >
            Next
          </button>
        </div>
      </Slide>
    </>
  );
};

export default Page2;
