import React from "react";
import Fade from "react-reveal/Fade";

export const TutorQuote = () => {
  return (
    <Fade bottom>
      <div className=" flex justify-center py-10 bg-[#ffd17d]">
        <p className=" mt-5 text-center text-gray-600">
          <span className=" text-3xl">“</span>
          <span className="text-white text-2xl font-semibold">
            Everyone who remembers his own educational experience remembers
            teachers, not methods and techniques. The teacher is the kingpin of
            the educational situation. He makes or breaks programs
          </span>
          <span className=" text-3xl">”</span>
          <br />
          <span className=" text-lg font-semibold">― George Evans.</span>
        </p>
      </div>
    </Fade>
  );
};

const Quote = () => {
  return (
    <Fade bottom>
      <div className=" flex justify-center py-10 bg-[#ffd17d]">
        <p className=" mt-5 text-center text-gray-600">
          <span className=" text-3xl">“</span>
          <span className="text-white text-2xl font-semibold">
            Every student can learn, just not on the same day, or in the same
            way.
          </span>
          <span className=" text-3xl">”</span>
          <br />
          <span className=" text-lg font-semibold">― George Evans.</span>
        </p>
      </div>
    </Fade>
  );
};

export default Quote;
