import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/NeedTutor/Login";
import Register from "./pages/BecomeTutor/Register";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { PageNumberProvider } from "./context/PageNumber";
import axios from "axios";
import { useEffect } from "react";

// seeting the base api for api calls
axios.defaults.baseURL = `${process.env.REACT_APP_MAIN_API}`;

function App() {
  useEffect(()=>{

    // adding time limitation to the form
    if(!localStorage.getItem("formTime")){
      const formdata = {
        date: new Date()
      }
      localStorage.setItem("formTime", JSON.stringify(formdata))
    }
    else{
      // console.log(typeof JSON.parse( localStorage.getItem('formTime')))
      const date1 = JSON.parse(localStorage.getItem("formTime"))
      const date2 = new Date();
      const diffTime = Math.abs(date2 - new Date(date1.date) );
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      // console.log(diffDays + " days");

      // setting it to 24 hours
      if(diffDays >= 2){
        localStorage.clear()
        window.location.reload(true)
      }
    }
  },[])

  return (
    <div className="App">
      <PageNumberProvider>
        <Navbar />
        <Routes>
          <Route path="/need_a_tutor" element={<Login />} />
          <Route path="/become_a_shadow_teacher" element={<Register />} />
        </Routes>
        <Footer />
      </PageNumberProvider>
    </div>
  );
}

export default App;
