import React from "react";
import Fade from "react-reveal/Fade";

const Footer = () => {
  return (
    <Fade bottom>
      <div className="w-full py-10 bg-[#06b6c3]">
        <div className=" px-10 py-10 md:px-28 grid grid-cols-1 md:grid-cols-4">
          <div className="p-4 text-left">
            <div className="heading mt-3 text-3xl font-semibold">
              <a>Hyderabad</a>
            </div>
            <div className="subheading mt-4 text-lg">
              <a
                className="underline underline-offset-2"
                href="https://shadow.homeshiksha.com/?page_id=156"
              >
                Shadow teacher near me in Hyderabad
              </a>
            </div>
            <div className="subheading mt-4 text-lg">
              <a
                className="underline underline-offset-2"
                href="https://shadow.homeshiksha.com/?page_id=207"
              >
                Special educator near me in Hyderabad
              </a>
            </div>
          </div>
          <div className="p-4 text-left">
            <div className="heading mt-3 text-3xl font-semibold">Bangalore</div>
            <div className="subheading mt-4 text-lg">
              <a
                className="underline underline-offset-2"
                href="https://shadow.homeshiksha.com/?page_id=185"
              >
                Shadow teacher near me in Bangalore{" "}
              </a>
            </div>
            <div className="subheading mt-4 text-lg">
              <a
                className="underline underline-offset-2"
                href="https://shadow.homeshiksha.com/?page_id=213"
              >
                Special educator near me in Bangalore
              </a>
            </div>
          </div>
          <div className="p-4 text-left">
            <div className="heading mt-3 text-3xl font-semibold">
              <a>Mumbai</a>
            </div>
            <div className="subheading mt-4 text-lg">
              <a
                className="underline underline-offset-2"
                href="https://shadow.homeshiksha.com/?page_id=195"
              >
                Shadow teacher near me in Mumbai
              </a>
            </div>
            <div className="subheading mt-4 text-lg">
              <a
                className="underline underline-offset-2"
                href="https://shadow.homeshiksha.com/?page_id=215"
              >
                Special educator near me in Mumbai
              </a>
            </div>
          </div>
          <div className="p-4 text-left">
            <div className="heading mt-3 text-3xl font-semibold">
              <a>Dehradun</a>
            </div>
            <div className="subheading mt-4 text-lg">
              <a
                className="underline underline-offset-2"
                href="https://shadow.homeshiksha.com/?page_id=197"
              >
                Shadow teacher near me in Dehradun
              </a>
            </div>
            <div className="subheading mt-4 text-lg">
              <a
                className="underline underline-offset-2"
                href="https://shadow.homeshiksha.com/?page_id=217"
              >
                Special educator near me in Dehradun
              </a>
            </div>
          </div>
          <div className="p-4 text-left">
            <div className="heading mt-3 text-3xl font-semibold">
              <a>Chennai</a>
            </div>
            <div className="subheading mt-4 text-lg">
              <a
                className="underline underline-offset-2"
                href="https://shadow.homeshiksha.com/?page_id=199"
              >
                Shadow teacher near me in Chennai
              </a>
            </div>
            <div className="subheading mt-4 text-lg">
              <a
                className="underline underline-offset-2"
                href="https://shadow.homeshiksha.com/?page_id=219"
              >
                Special educator near me in Chennai
              </a>
            </div>
          </div>
          <div className="p-4 text-left">
            <div className="heading mt-3 text-3xl font-semibold">
              <a>Gurgaon</a>
            </div>
            <div className="subheading mt-4 text-lg">
              <a
                className="underline underline-offset-2"
                href="https://shadow.homeshiksha.com/?page_id=201"
              >
                Shadow teacher near me in Gurgaon
              </a>
            </div>
            <div className="subheading mt-4 text-lg">
              <a
                className="underline underline-offset-2"
                href="https://shadow.homeshiksha.com/?page_id=221"
              >
                Special educator near me in Gurgaon
              </a>
            </div>
          </div>
          <div className="p-4 text-left">
            <div className="heading mt-3 text-3xl font-semibold">
              <a>Delhi</a>
            </div>
            <div className="subheading mt-4 text-lg">
              <a
                className="underline underline-offset-2"
                href="https://shadow.homeshiksha.com/?page_id=203"
              >
                Shadow teacher near me in Delhi
              </a>
            </div>
            <div className="subheading mt-4 text-lg">
              <a
                className="underline underline-offset-2"
                href="https://shadow.homeshiksha.com/?page_id=223"
              >
                Special educator near me in Delhi
              </a>
            </div>
          </div>
          <div className="p-4 text-left">
            <div className="heading mt-3 text-3xl font-semibold">
              <a>Pune</a>
            </div>
            <div className="subheading mt-4 text-lg">
              <a
                className="underline underline-offset-2"
                href="https://shadow.homeshiksha.com/?page_id=205"
              >
                Shadow teacher near me in Pune
              </a>
            </div>
            <div className="subheading mt-4 text-lg">
              <a
                className="underline underline-offset-2"
                href="https://shadow.homeshiksha.com/?page_id=225"
              >
                Special educator near me in Pune
              </a>
            </div>
          </div>
          <div className="p-4 text-left">
            <div className="heading mt-3 text-3xl font-semibold">
              <a>Others</a>
            </div>
            <div className="subheading mt-4 text-lg">
              <a className="underline underline-offset-2" href="">
                <a href="https://shadow.homeshiksha.com/?page_id=227">
                  Home tutors for Autism and ADHD
                </a>
                ,
                <a href="https://shadow.homeshiksha.com/?page_id=230">
                  {" "}
                  Learning Disability
                </a>
                ,
                <a href="https://shadow.homeshiksha.com/?page_id=233">
                  {" "}
                  Dyslexia and slow learners
                </a>
              </a>
            </div>
            <div className="subheading mt-4 text-lg">
              <a className="underline underline-offset-2" href="">
                Personal special educator tutor
              </a>
            </div>
            <div className="subheading mt-4 text-lg">
              <a className="underline underline-offset-2" href="">
                Personal shadow tutor
              </a>
            </div>
            <div className="subheading mt-4 text-lg">
              <a
                className="underline underline-offset-2"
                href="https://shadow.homeshiksha.com/?page_id=243"
              >
                Special educator jobs
              </a>
            </div>
            <div className="subheading mt-4 text-lg">
              <a
                className="underline underline-offset-2"
                href="https://shadow.homeshiksha.com/?page_id=246"
              >
                Shadow teacher jobs
              </a>
            </div>
          </div>
        </div>
        <div className=" px-10 py-10 md:px-28 grid grid-cols-1 md:grid-cols-3">
          <div className=" p-4 text-center">
            <div className="w-full flex justify-center text-center">
              <svg
                className="w-16 h-16"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                id="facebook"
              >
                <path
                  fill="#1976D2"
                  d="M14 0H2C.897 0 0 .897 0 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V2c0-1.103-.897-2-2-2z"
                ></path>
                <path
                  fill="#FAFAFA"
                  fill-rule="evenodd"
                  d="M13.5 8H11V6c0-.552.448-.5 1-.5h1V3h-2a3 3 0 0 0-3 3v2H6v2.5h2V16h3v-5.5h1.5l1-2.5z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <div className="font-semibold mt-3 text-2xl">
              <a href="https://www.facebook.com/HomeShiksha">FACEBOOK</a>
            </div>
            <div className="font-semibold mt-3 text-md">JOIN US</div>
          </div>
          <div className=" p-4 text-center">
            <div className="w-full flex justify-center text-center">

              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-16 h-16"
                viewBox="0 0 16 16"
                id="instagram"
              >
                <linearGradient
                  id="a"
                  x1="1.464"
                  x2="14.536"
                  y1="14.536"
                  y2="1.464"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#FFC107"></stop>
                  <stop offset=".507" stop-color="#F44336"></stop>
                  <stop offset=".99" stop-color="#9C27B0"></stop>
                </linearGradient>
                <path
                  fill="url(#a)"
                  d="M11 0H5a5 5 0 0 0-5 5v6a5 5 0 0 0 5 5h6a5 5 0 0 0 5-5V5a5 5 0 0 0-5-5zm3.5 11c0 1.93-1.57 3.5-3.5 3.5H5c-1.93 0-3.5-1.57-3.5-3.5V5c0-1.93 1.57-3.5 3.5-3.5h6c1.93 0 3.5 1.57 3.5 3.5v6z"
                ></path>
                <linearGradient
                  id="b"
                  x1="5.172"
                  x2="10.828"
                  y1="10.828"
                  y2="5.172"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#FFC107"></stop>
                  <stop offset=".507" stop-color="#F44336"></stop>
                  <stop offset=".99" stop-color="#9C27B0"></stop>
                </linearGradient>
                <path
                  fill="url(#b)"
                  d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 6.5A2.503 2.503 0 0 1 5.5 8c0-1.379 1.122-2.5 2.5-2.5s2.5 1.121 2.5 2.5c0 1.378-1.122 2.5-2.5 2.5z"
                ></path>
                <linearGradient
                  id="c"
                  x1="11.923"
                  x2="12.677"
                  y1="4.077"
                  y2="3.323"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#FFC107"></stop>
                  <stop offset=".507" stop-color="#F44336"></stop>
                  <stop offset=".99" stop-color="#9C27B0"></stop>
                </linearGradient>
                <circle cx="12.3" cy="3.7" r=".533" fill="url(#c)"></circle>
              </svg>
            </div>
            <div className="font-semibold mt-3 text-2xl">
              <a href="https://www.instagram.com/homeshiksha/">Instagram</a>
            </div>
            <div className="font-semibold mt-3 text-md">JOIN US</div>
          </div>
          <div className=" p-4 text-center">
            <div className="w-full flex justify-center text-center">
              <svg
                className="w-16 h-16"
                xmlns="http://www.w3.org/2000/svg"
                width="2500"
                height="2500"
                viewBox="7.025 7.025 497.951 497.95"
                id="linkedin"
              >
                <linearGradient
                  id="a"
                  x1="-974.482"
                  x2="-622.378"
                  y1="1306.773"
                  y2="1658.877"
                  gradientTransform="translate(1054.43 -1226.825)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#2489be"></stop>
                  <stop offset="1" stop-color="#0575b3"></stop>
                </linearGradient>
                <path
                  fill="url(#a)"
                  d="M256 7.025C118.494 7.025 7.025 118.494 7.025 256S118.494 504.975 256 504.975 504.976 393.506 504.976 256C504.975 118.494 393.504 7.025 256 7.025zm-66.427 369.343h-54.665V199.761h54.665v176.607zM161.98 176.633c-17.853 0-32.326-14.591-32.326-32.587 0-17.998 14.475-32.588 32.326-32.588s32.324 14.59 32.324 32.588c.001 17.997-14.472 32.587-32.324 32.587zm232.45 199.735h-54.4v-92.704c0-25.426-9.658-39.619-29.763-39.619-21.881 0-33.312 14.782-33.312 39.619v92.704h-52.43V199.761h52.43v23.786s15.771-29.173 53.219-29.173c37.449 0 64.257 22.866 64.257 70.169l-.001 111.825z"
                ></path>
              </svg>
              
            </div>
            <div className="font-semibold mt-3 text-2xl">
              <a href="https://www.linkedin.com/company/home-shiksha/">
                LINKEDIN
              </a>
            </div>
            <div className="font-semibold mt-3 text-md">JOIN US</div>
          </div>
        </div>
        <div className=" px-10 py10 md:px-28 flex justify-center">
          <div className=" text-center">
            <div className="flex justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="200"
                viewBox="0 0 512 512"
                id="heart"
              >
                <path d="M256 448l-30.164-27.211C118.718 322.442 48 258.61 48 179.095 48 114.221 97.918 64 162.4 64c36.399 0 70.717 16.742 93.6 43.947C278.882 80.742 313.199 64 349.6 64 414.082 64 464 114.221 464 179.095c0 79.516-70.719 143.348-177.836 241.694L256 448z"></path>
              </svg>
            </div>
            <div className="text-center mt-3 text-4xl font-semibold">
              HomeShiksha
            </div>
            <div className="text-center mt-3 text-md">
              +91-9133416664 +91-9642222147
            </div>
            <div className="text-center mt-3 text-md">
              contact@homeshiksha.com
            </div>
            <div className="text-center mt-3 text-md">
              <a href="https://shadow.homeshiksha.com/?page_id=402">
                Parents Terms and conditions
              </a>{" "}
              |{" "}
              <a href="https://shadow.homeshiksha.com/?page_id=406">
                Tutors Terms and conditions
              </a>
            </div>
            <div className="text-center mt-3 text-md">
              This website is owned by Stelliam Services Private Limited |
              Copyright © 2023 HomeShiksha All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default Footer;
