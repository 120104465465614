import React, { useContext, useState, useEffect } from "react";
import Slide from "react-reveal/Slide";
import PageNumberContext from "../../context/PageNumber";
import cogoToast from "cogo-toast";

const Page1 = () => {
  const { nextPage } = useContext(PageNumberContext);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [challenges, setChallenges] = useState("");
  const [syllabus, setSyllabus] = useState("");

  useEffect(() => {
    if (localStorage.getItem("loginPage1")) {
      const formData = JSON.parse(localStorage.getItem("loginPage1"));
      // console.log(formData);
      setName(formData.name);
      setNumber(formData.number)
      setEmail(formData.email);
      setGender(formData.gender);
      setChallenges(formData.challenges);
      setSyllabus(formData.syllabus);
    }
  }, []);

  // for updating local storage
  const updateLocalStorage = (value, field) => {
    let formData = JSON.parse(localStorage.getItem("loginPage1"));
    if (field === "name") {
      formData.name = value;
    } else if (field === "number") {
      formData.number = value;
    } else if (field === "email") {
      formData.email = value;
    } else if (field === "gender") {
      formData.gender = value;
    } else if(field === "challenges"){
      formData.challenges = value
    } else if(field === "syllabus"){
      formData.syllabus = value
    }

    localStorage.setItem("loginPage1", JSON.stringify(formData));
  };

  // when input values are changed
  const onInputChange = (e) => {
    // console.log(e.target.id);
    if (e.target.id === "name") {
      // console.log(e.target.value);
      setName(e.target.value);
      updateLocalStorage(e.target.value, e.target.id);
    } else if (e.target.id === "number") {
      // console.log(e.target.value);
      setNumber(e.target.value);
      updateLocalStorage(e.target.value, e.target.id);
    } else if (e.target.id === "email") {
      // console.log(e.target.value);
      setEmail(e.target.value);
      updateLocalStorage(e.target.value, e.target.id);
    } else if (e.target.id === "gender") {
      // console.log(e.target.value);
      setGender(e.target.value);
      updateLocalStorage(e.target.value, e.target.id);
    } else if (e.target.id === "challenges") {
      // console.log(e.target.value);
      setChallenges(e.target.value);
      updateLocalStorage(e.target.value, e.target.id);
    } else if (e.target.id === "syllabus") {
      // console.log(e.target.value);
      setSyllabus(e.target.value);
      updateLocalStorage(e.target.value, e.target.id);
    }
  };

  const filedChecking = () =>{

    if(name == "" || number == "" || email == "" || gender == "" || challenges == "" || syllabus == ""){
      
      cogoToast.warn("Please enter all the fields!", {position:'top-right'})
      return false
    }else if(number.length < 10){
      cogoToast.warn("Phone number must be of minumum 10 character!", {position:'top-right'})
      return false
    }else if(number.length > 14){
      cogoToast.warn("Phone number can be maximum of 14 Character!", {position:'top-right'})
      return false
    }

    return true
  }

  // for next oage
  const next = () => {
    // filedChecking()
    if(filedChecking() === true){
      nextPage();

    }    

    
  };

  return (
    <>
      <Slide right>
        <div className="mt-3 mb-3 text-left space-y-1">
          <label className=" text-md text-left">Your Name</label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => onInputChange(e)}
            placeholder="your name"
            className="name px-3 py-2 border border-gray-300 outline-none rounded-lg w-full
            focus:border-blue-500 transform duration-300 ease-in-out
            "
          />
        </div>
        <div className="mt-3 mb-3 text-left space-y-1">
          <label className=" text-md text-left">Contact No:</label>
          <input
            id="number"
            type="number"
            value={number}
            onChange={(e) => onInputChange(e)}
            placeholder="Your phone number"
            className="name px-3 py-2 border border-gray-300 outline-none rounded-lg w-full
            focus:border-blue-500 transform duration-300 ease-in-out
            "
          />
        </div>
        <div className="mt-3 mb-3 text-left space-y-1">
          <label className=" text-md text-left">Email id</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => onInputChange(e)}
            placeholder="email id"
            className="email px-3 py-2 border border-gray-300 outline-none rounded-lg w-full
            focus:border-blue-500 transform duration-300 ease-in-out
            "
          />
        </div>
        <div className="mt-3 mb-3 text-left space-y-1">
          <label className=" text-md text-left">Student gender</label>
          <div>
            <select
              id="gender"
              value={gender}
              onChange={(e) => onInputChange(e)}
              className="gender px-3 py-2 text-gray-500 border border-gray-300 outline-none rounded-lg w-full
              focus:border-blue-500 transform duration-300 ease-in-out
              "
            >
              <option className=" text-gray-300">select</option>
              <option>Male</option>
              <option>Female</option>
            </select>
          </div>
        </div>
        <div className="mt-3 mb-3 text-left space-y-1">
          <label className=" text-md text-left">
            Please share your challenges and which subjects you wish to enquire
            for
          </label>
          <textarea
            value={challenges}
            onChange={(e) => onInputChange(e)}
            id="challenges"
            placeholder="type here..."
            className="challenges px-3 py-2 border border-gray-300 outline-none rounded-lg w-full
            focus:border-blue-500 transform duration-300 ease-in-out
            "
          />
        </div>
        <div className="mt-3 mb-3 text-left space-y-1">
          <label className=" text-md text-left">Syllabus and class</label>
          <input
            value={syllabus}
            id="syllabus"
            onChange={(e) => onInputChange(e)}
            placeholder="syllabus"
            className=" px-3 py-2 border border-gray-300 outline-none rounded-lg w-full
            focus:border-blue-500 transform duration-300 ease-in-out"
          />
        </div>
        <div className="mt-3 mb-3 text-left space-y-1 flex md:justify-end">
          <button
            onClick={() => next()}
            className=" px-4 py-2 rounded-lg outline-none bg-cyan-500 hover:bg-cyan-600 text-white transform duration-300"
          >
            Next
          </button>
        </div>
      </Slide>
    </>
  );
};

export default Page1;
