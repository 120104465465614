import React from "react";
import Bars3Icon from "@heroicons/react/20/solid/Bars3Icon";
import { Link } from "react-router-dom";

const Navbar = () => {
  const toggleMenu = () => {
    // menu.classList.toggle('hidden')
    document.querySelector(".mobile-menu").classList.toggle("hidden");
  };

  return (
    <div className=" bg-black text-white">
      <div className="py-3">
        <div className="flex justify-between w-screen">
          <div className="primary ml-8 flex items-center gap-4 md:gap-6">
            <Link to="https://shadow.homeshiksha.com/">
              <h2 className="text-2xl md:text-3xl font-semibold">
                ShadowShiksha
              </h2>
            </Link>
          </div>

          <div className="secondary hidden md:flex font-semibold items-center gap-8">
            <Link to="https://shadow.homeshiksha.com/">
              <a className="hover:text-cyan-600 transform duration-300">Home</a>
            </Link>
            <Link to="/need_a_tutor">
              <a className="hover:text-cyan-600 transform duration-300">
                Need a tutor
              </a>
            </Link>
            <Link to="/become_a_shadow_teacher">
              <button
                className="bg-cyan-500 py-2 px-4 rounded-sm mr-10 text-white 
                    hover:bg-cyan-600 transition duration-300 ease-in-out"
              >
                Become a Tutor
              </button>
            </Link>
          </div>

          {/* for mobile view hamburger button */}
          <div className="md:hidden flex items-center h-12 w-12 mr-3">
            <button
              onClick={() => toggleMenu()}
              className="mobile-menu-button py-2 px-3 focus:ring-2 focus:ring-gray-600 rounded-sm"
            >
              <Bars3Icon className="block h-6 w-6" />
            </button>
          </div>
        </div>

        {/* list for mobile view */}
        <div
          className="mobile-menu font-semibold hidden md:hidden p-5 w-screen"
          toggle="hidden"
        >
          <Link to="https://shadow.homeshiksha.com/">
            <button
              className="my-2 w-full py-2 px-4 rounded-sm mr-10 
                     transition duration-300 ease-in-out"
            >
              Home
            </button>
          </Link>
          <Link to="/need_a_tutor">
            <button
              className=" my-2 w-full py-2 px-4 rounded-sm mr-10 
                     transition duration-300 ease-in-out"
            >
              Need a Tutor
            </button>
          </Link>
          <Link to="/become_a_shadow_teacher">
            <button
              className="bg-cyan-500 my-2 w-full py-2 px-4 rounded-lg mr-10 text-white 
                    hover:bg-cyan-600 transition duration-300 ease-in-out"
            >
              Become a tutor
            </button>
          </Link>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default Navbar;
