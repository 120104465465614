import React, { useContext, useEffect } from "react";
import UsersIcon from "@heroicons/react/20/solid/UsersIcon";
import PageNumberContext from "../../context/PageNumber";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import image1 from "../image1.jpg";
import Slide from "react-reveal/Slide";
import Quote from "../../components/Quote";

const Login = () => {
  const { pageNumber, setPageNumber } = useContext(PageNumberContext);

  useEffect(() => {
    // checking the localStorage for previuos session data
    if (!localStorage.getItem("loginPage1")) {
      // creating a cookie for storing the new session data for page1
      // console.log("present");
      const formData = {
        name: "",
        number: "",
        email: "",
        gender: "",
        challenges: "",
        syllabus: "",
      };
      localStorage.setItem("loginPage1", JSON.stringify(formData));
    } else {
      // console.log(localStorage.getItem("loginPage1"));
    }

    // checking for form time
    if (!localStorage.getItem("formTime")) {
      // creating a form time if it's not there
      const createdAt = new Date().getTime().toString();
      localStorage.setItem("formTime", JSON.stringify(createdAt));
    } else {
      if (localStorage.getItem("loginPage3")) {
        setPageNumber(3);
      } else if (localStorage.getItem("loginPage2")) {
        setPageNumber(2);
      } else {
        setPageNumber(1);
      }
    }
  }, []);

  return (
    <div className="w-full h-full">
      <div className=" grid h-full w-full space-x-3 grid-cols-1 md:grid-cols-2">
        <div className="h-full p-10 ">
          <Slide left>
            <div className=" flex justify-center">
              <div className="image w-2/3 h-1/2 flex justify-center py-4">
                <img
                  className="drop-shadow-2xl rounded-s-full rounded-e-full rounded-t-full"
                  alt="login image"
                  src={image1}
                ></img>
              </div>
            </div>
            <div
              className="show-content drop-shadow-2xl space-y-4 text-left p-5
             bg-cyan-500 text-white rounded-lg font-semibold"
            >
              <div className="question text-lg">
                1) Are you looking for Special Educator?
              </div>
              <div className="question text-lg">
                2) Are you looking for Shadow teacher?
              </div>
              <div className="question text-lg">
                3) Are you looking personal trainee for Autism, ADHD, Learning
                Disability, Dyslexia & Slow learners?
              </div>
              <div className="question text-lg">
                Perplexed, Confused, Worried ??? but why??
              </div>
              <div className="question text-lg">
                When you have Home Shiksha around you.
              </div>
            </div>
          </Slide>
        </div>
        <div>
          <div className="p-10">
            <div className=" text-left text-2xl font-semibold flex space-x-6 items-center text-gray-600 justify-start">
              <UsersIcon className=" w-6 h-6" />
              <label>Need a tutor</label>
            </div>

            {pageNumber == 1 ? (
              <Page1 />
            ) : pageNumber == 2 ? (
              <Page2 />
            ) : pageNumber == 3 ? (
              <Page3 />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {/* displaying quote section  */}
      <Quote />

    </div>
  );
};

export default Login;
