import React, { useContext, useState, useEffect } from "react";
import Slide from "react-reveal/Slide";
import PageNumberContext from "../../context/PageNumber";
import cogoToast from "cogo-toast";
import axios from "axios";

const Page3 = () => {
  const { prevPage } = useContext(PageNumberContext);
  const [currentCity, setCurrentCity] = useState("");
  const [locality, setLocality] = useState("");
  const [address, setAddress] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [showList, setShowList] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("loginPage3")) {
      const formData = JSON.parse(localStorage.getItem("loginPage3"));
      // console.log(formData);
      // setCurrentCity(formData.subjectTeach);
      // setLocality(formData.extraCurricular);
      setAddress(formData.address);
    } else {
      const formData = {
        currentCity: "",
        locality: "",
        address: "",
      };
      localStorage.setItem("loginPage3", JSON.stringify(formData));
    }
  }, []);

  const updateLocalStorage = (value, field) => {
    let formData = JSON.parse(localStorage.getItem("loginPage3"));
    if (field == "currentCity") {
      formData.currentCity = value;
    } else if (field == "locality") {
      formData.locality = value;
    } else if (field == "address") {
      formData.address = value;
    }

    localStorage.setItem("loginPage3", JSON.stringify(formData));
  };

  const fetchLocallity = (id) => {
    axios
      .get(`v1/locality?cityId=${id}`)
      .then((res) => {
        // console.log(res.data);
        const data = res.data;
        setShowList(data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const onInputChange = (e) => {
    // console.log(e.target.id);
    if (e.target.id === "currentCity") {
      // console.log(e.target.value);
      setCurrentCity(e.target.value);
      fetchLocallity(e.target.value);
      updateLocalStorage(e.target.value, e.target.id);
    } else if (e.target.id === "locality") {
      // console.log(e.target.value);
      setLocality(e.target.value);
      updateLocalStorage(e.target.value, e.target.id);
    } else if (e.target.id === "address") {
      // console.log(e.target.value);
      setAddress(e.target.value);
      updateLocalStorage(e.target.value, e.target.id);
    }
  };

  const tandc = () => {
    window.open("https://shadow.homeshiksha.com/?page_id=402");
  };

  const prev = () => {
    prevPage();
  };

  const handleSubmit = () => {
    if (currentCity == "" || locality == "" || address == "") {
      cogoToast.warn("Please Enter all fileds!", { position: "top-right" });
    } else if (isChecked === false) {
      cogoToast.warn("Please Agree with Terms and Conditions", {
        position: "top-right",
      });
    } else {
      const formData = JSON.parse(localStorage.getItem("loginPage1"));
      const formData2 = JSON.parse(localStorage.getItem("loginPage2"));
      // console.log(formData.name.length);
      let fullname = formData.name;
      let index;

      for (let i = fullname.length; i > 0; i--) {
        if (fullname[i] == " ") {
          index = i;
          break;
        }
      }

      const firstname = fullname.substring(0, index);
      const lastname = fullname.substring(index, fullname.length);
      // console.log(firstname, lastname);

      const data = {
        url: "v1/student",
        method: "POST",
        data: {
          studentData: {
            st_firstname: firstname,
            st_lastname: lastname,
            st_gender: formData.gender,
            st_phone: formData.number,
            st_email: formData.email,
            requirement_subjects: formData.challenges,
            class_detail: formData.syllabus,
            male_or_female_tutor: formData2.tutorGender,
            how_many_days_in_a_week: formData2.nodays,
            when_you_like_to_start: formData2.tuitionDate,
            shadow_service_needed: formData2.service,
            st_address: address,
            st_city: currentCity,
            locality: locality,
          },
        },
      };

      // console.log(data.data);

      axios(data)
        .then((res) => {
          // console.log(res.data);
          cogoToast.success("Your requirements have been taken successfully!", {
            position: "top-center",
            heading: "Success !",
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.reload(true);
          }, 5000);
        })
        .catch((err) => {
          if (err.response.data.message) {
            cogoToast.error(`${err.response.data.message}`);
          } else {
            cogoToast.error(`${err.response.data.errors[0].msg}`);
          }
          // console.log(err.response.data.message);
        });
    }
  };

  return (
    <>
      <Slide right>
        {/* select city  */}
        <div className="mt-3 mb-3 text-left space-y-1">
          <label className=" text-md text-left">Current city</label>
          <div>
            <select
              id="currentCity"
              value={currentCity}
              onChange={(e) => onInputChange(e)}
              className="px-3 py-2 text-gray-500 border border-gray-300 outline-none rounded-lg w-full
              focus:border-blue-500 transform duration-300 ease-in-out"
            >
              <option>select</option>
              <option value="1">Hyderabad</option>
              <option value="2">Bangalore</option>
              <option value="3">Pune</option>
              <option value="4">Dehradun</option>
              <option value="6">Online-Dun</option>
              <option value="7">Raipur/Bhilai/Durg</option>
              <option value="8">Chennai</option>
              <option value="9">Vijayawada</option>
              <option value="10">Mumbai</option>
              <option value="11">Gurgaon</option>
              <option value="12">Delhi</option>
              <option value="5">Other</option>
              <option value="16">Mysore</option>
            </select>
          </div>
        </div>

        <div className="mt-3 mb-3 text-left space-y-1">
          <label className=" text-md text-left">Locality</label>
          <div>
            <select
              id="locality"
              value={locality}
              onChange={(e) => onInputChange(e)}
              className="px-3 py-2 text-gray-500 border border-gray-300 outline-none rounded-lg w-full
              focus:border-blue-500 transform duration-300 ease-in-out"
            >
              <option>select</option>
              {showList.map((j) => {
                return <option key={j.area_id}>{j.area_name}</option>;
              })}
            </select>
          </div>
        </div>

        <div className="mt-3 mb-3 text-left space-y-1">
          <label className=" text-md text-left">Address</label>
          <textarea
            id="address"
            value={address}
            onChange={(e) => onInputChange(e)}
            placeholder="type here..."
            className=" px-3 py-2 border border-gray-300 outline-none rounded-lg w-full
            focus:border-blue-500 transform duration-300 ease-in-out"
          />
        </div>
        <div className="mt-3 mb-3 text-left space-y-1">
          <div className="flex space-x-3 px-2 items-center">
            <input
              onClick={() => setIsChecked(!isChecked)}
              className="h-4 w-4"
              type="checkbox"
            />
            <p>
              I have read and accept{" "}
              <span
                className=" text-blue-400 cursor-pointer"
                onClick={() => tandc()}
              >
                {" "}
                terms and conditions{" "}
              </span>
            </p>
          </div>
        </div>

        <div className="mt-3 mb-3 text-left space-x-3 space-y-1 flex md:justify-end">
          <button
            onClick={() => prev()}
            className=" px-4 py-2 outline-none rounded-lg border text-cyan-600"
          >
            Prev
          </button>
          <button
            onClick={() => handleSubmit()}
            className=" px-4 py-2 rounded-lg outline-none bg-cyan-500 hover:bg-cyan-600 
          text-white transform duration-300"
          >
            Submit
          </button>
        </div>
      </Slide>
    </>
  );
};

export default Page3;
