import { createContext, useState } from "react";

const PageNumberContext = createContext();

export const PageNumberProvider = ({ children }) => {
  // state for storing the state of the page
  const [pageNumber, setPageNumber] = useState(1);

  // for moving to the next section
  const nextPage = () => {
    let temp = pageNumber;
    temp++;
    setPageNumber(temp);
  };

  // for moving to previous section
  const prevPage = () => {
    let temp = pageNumber;
    temp--;
    setPageNumber(temp);
  };

  return (
    <PageNumberContext.Provider value={{ pageNumber, nextPage, prevPage, setPageNumber }}>
      {children}
    </PageNumberContext.Provider>
  );
};

export default PageNumberContext;