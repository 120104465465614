import { Dialog, Transition } from "@headlessui/react";
import { useEffect } from "react";
import { Fragment, useState } from "react";
import { updateLocalStorage } from "./Page2";

let selectedArray = []

export default function ServiceMultiselect() {
  let [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("Select");

  // setting state for multiple selection 
  const [isHome, setIsHome] = useState(false);
  const [isSchool, setIsSchool] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [isTuition, setIsTuition] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("loginPage2")) {
      const formData = JSON.parse(localStorage.getItem("loginPage2"));
      
        setSelected(formData.service);

        if(formData.service.includes("home")){
          setIsHome(true)
          selectedArray.push("Special educators at home")
        }

        if(formData.service.includes("School")){
          setIsSchool(true)
          selectedArray.push("School shadow teacher")
        }

        if(formData.service.includes("Online")){
          setIsOnline(true)
          selectedArray.push("Online")
        }

        if(formData.service.includes("Tuition")){
          setIsTuition(true)
          selectedArray.push("Tuition support")
        }
    
    }
  }, []);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const onChange = (e) => {
    let display = selected;
    // console.log('51', display)
    // updating the if the it matches the string select
    if(display.includes(e.target.value)){

      display.replace(`${e.target.value}`, '')
      // console.log('56', display)
      // setSelected(display)

      if(e.target.value.includes("home")){
        // display.replace('Special educators at home', '')
        setIsHome(! isHome)
        // setSelected(display)
      }
      else if(e.target.value.includes("School")){
        // display.replace('School shadow teacher', '')
        setIsSchool(! isSchool)
        // setSelected(display)
      }
      else if(e.target.value.includes("Online")){
        // display.replace('Online (Special educator)', '')
        setIsOnline(! isOnline)
        // setSelected(display)
      }
      else if(e.target.value.includes("Tuition")){
        // display.replace('Tuition support', '')
        setIsTuition(! isTuition)
        // setSelected(display)
      }

      let index = selectedArray.indexOf(e.target.value)
      // console.log('87',index)
      selectedArray.splice(index, 1)
      display = ""

      for(let i=0; i<selectedArray.length; i++){
        display += ` ${selectedArray[i]}`;
        if(i != selectedArray.length){
          display += ' ,';
        }
      }
      setSelected(display)

      updateLocalStorage(display, 'service')
      

    }
    else{
      if (selected === "Select") {
        display = "";
        display = e.target.value;
        setSelected(display);
        updateLocalStorage(display, "service");
      } else {
        display += ` , ${e.target.value}`;
        setSelected(display);
        updateLocalStorage(display, "service");
        selectedArray.push(e.target.value)
      }
  
      if(e.target.value === "Special educators at home"){
        setIsHome(! isHome);
  
      } else if(e.target.value === "School shadow teacher"){
        setIsSchool(! isSchool);
  
      } else if(e.target.value === "Online"){
        setIsOnline(! isOnline);
  
      } else if(e.target.value === "Tuition support"){
        setIsTuition(! isTuition);
  
      }
    }

    


    // console.log(e.target.value);
  };

  return (
    <>
      <div className="inset-0 w-full flex items-center justify-center">
        <button
          type="button"
          onClick={openModal}
          className="rounded-md w-full text-left text-md border border-gray-300 px-4 py-2
          text-gray-400 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 
          focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          {selected}
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-medium text-cyan-500"
                  >
                    Select Services
                  </Dialog.Title>
                  <div className="my-4">
                    <div className=" space-x-2 place-items-center">
                      <input
                        onChange={(e) => onChange(e)}
                        checked={isHome}
                        type="checkbox"
                        className=" w-5 h-4 outline-none"
                        value="Special educators at home"
                      />
                      <label className=" text-lg text-left">
                        Special educators at home
                      </label>
                    </div>
                    <div className=" space-x-2 place-items-center">
                      <input
                        onChange={(e) => onChange(e)}
                        type="checkbox"
                        checked={isSchool}
                        className=" w-5 h-4 outline-none"
                        value="School shadow teacher"
                      />
                      <label className=" text-lg text-left">
                        School shadow teacher
                      </label>
                    </div>
                    <div className=" space-x-2 place-items-center">
                      <input
                        
                        checked={isOnline}
                        onChange={(e) => onChange(e)}
                        type="checkbox"
                        className=" w-5 h-4 outline-none"
                        value="Online"
                      />
                      <label className=" text-lg text-left">
                        Online (Special educator)
                      </label>
                    </div>
                    <div className=" space-x-2 place-items-center">
                      <input
                        
                        checked={isTuition}
                        onChange={(e) => onChange(e)}
                        type="checkbox"
                        className=" w-5 h-4 outline-none"
                        value="Tuition support"
                      />
                      <label className=" text-lg text-left">
                        Tuition support
                      </label>
                    </div>
                  </div>

                  <div className="mt-4 w-full flex justify-center">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Apply
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
